/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------Heights hoverboxar--------------------*/
.hoverbox, .hoverbox_innerdiv {min-height:20vw; } 
.container .hoverbox, .container .hoverbox .hoverbox_innerdiv { min-height:calc(1100px / 4.5); }
.container.lrg .hoverbox, .container.lrg .hoverbox .hoverbox_innerdiv { min-height:calc(1300px / 4.5); }


/*General*/
.hoverboxar {padding: 40px 0; }
.hoverboxar, .hoverbox, .hoverbox_innerdiv { position: relative; }
.hoverbox_innerdiv {overflow: hidden; cursor: pointer; margin:0; text-align: center; width:100%;}
.hoverbox_image { position:absolute; width:100%; height:100%; z-index:0; background-size:cover; background-position: center center; border-radius:3px; }
.hoverbox_text {position: absolute; width: 100%; height: 100%; left: 0;  top: 0; color: #fff; padding: 35px; text-shadow: 0 0 15px rgba(0, 0, 0, 0.7); 
 display: flex;  align-items: center; flex-direction: column; justify-content: center;}
.hoverbox_text > a { position: absolute; width: 100%; height: 100%; left: 0;  top: 0; font-size: 0; opacity: 0; z-index: 10;}
.hoverbox_text h2 {font-size: 2.2em;  font-weight: 900; letter-spacing: 0.5pt; padding: 0 0 5px; text-transform: uppercase;  }
.hoverbox_text h2 span {font-size: 0.8em;  font-weight: 700; letter-spacing: 0.5pt; padding: 0 0 5px; text-transform: none;  }


.hoverbox_text p {margin:0; max-width:100%;  line-height: 1.2em; font-size:1.05em; }



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/

/*---------------------------FADE boxar------------------------------*/
.hoverbox_text { background: rgba(0, 0, 0, 0); transition: background 0.5s ease 0s;}
.fade .hoverbox_innerdiv:hover .hoverbox_text { background: rgba(0, 0, 0, 0.5); transition: background 0.5s ease 0s;}
.fade .hoverbox_innerdiv h2 {padding: 0 0 5px;}
.fade .hoverbox_innerdiv p { opacity: 0; transition: opacity 0.5s ease 0s;}
.fade .hoverbox_innerdiv:hover p {opacity: 1; transition: opacity 0.5s ease 0s;}
 
 
 
/*---------------------------CENTERBOX med border-----------------------------*/
.centerbox .hoverbox_innerdiv {background:#000; }
.centerbox .hoverbox_innerdiv img {opacity: 0.9; transition: opacity 0.35s ease 0s;}
.centerbox .hoverbox_innerdiv:hover img {opacity: 0.4;}
.centerbox .hoverbox_innerdiv:hover .hoverbox_text { background-color: rgba(0, 0, 0, 0);}
.centerbox .hoverbox_innerdiv .hoverbox_text:before { content: ""; position: absolute; right: 25px; left: 25px; top: 25px; bottom: 25px; opacity: 0;
 background:rgba(0, 0, 0, 0.9);  box-shadow: 0 0 0 30px rgba(255, 255, 255, 0.2); transform: scale3d(1.4, 1.4, 1); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.centerbox .hoverbox_innerdiv:hover .hoverbox_text:before  { opacity: 1;transform: scale3d(1, 1, 1);}
.centerbox .hoverbox_innerdiv h2 {position:relative; color:#fff;  }
 .centerbox .hoverbox_innerdiv:hover h2 {text-shadow:none;  }
.centerbox .hoverbox_innerdiv p { opacity: 0; padding: 0 1em 1em; transform: scale(1.5);transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.centerbox .hoverbox_innerdiv:hover p { opacity: 1; transform: scale3d(1, 1, 1);}

 

/*---------------------------WHITEBOX dropdown ruta m text----------------------------*/
.whitebox .hoverbox_innerdiv {text-align: left;}
.whitebox .hoverbox_innerdiv .hoverbox_text {z-index: 1; display:block; align-items: stretch; flex-direction: row; justify-content: flex-start; text-shadow:none; }
.whitebox .hoverbox_innerdiv .hoverbox_text:before { content: "";  position: absolute; top: 0; left: 2em; right: 2em; height: 50%; background: #fff; 
 transform: translate3d(0px, 4.5em, 0px) scale3d(1, 0, 1);transform-origin: 50% 0 0;transition: opacity 0.35s ease 0s, transform 0.35s ease 0s; z-index: -1;}
.whitebox .hoverbox_innerdiv:hover .hoverbox_text:before { opacity: 0.7; transform: translate3d(0px, 4.5em, 0px) scale3d(1, 1, 1);}
.whitebox .hoverbox_innerdiv p {color: #404040; opacity: 0;  padding: 2em 1em; transform: translate3d(0px, -10px, 0px); transition: opacity 0.35s ease 0s, transform 0.35s ease 0s;}
.whitebox .hoverbox_innerdiv:hover p {opacity: 1;transform: translate3d(0px, 0px, 0px);}
.whitebox .hoverbox_innerdiv p a {color: #404040; margin: 0 0.5em;}
.whitebox .hoverbox_innerdiv p a:hover, .whitebox .hoverbox_innerdiv p a:focus  { opacity: 0.6;}
 
 



 

 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
.hoverbox,.hoverbox_innerdiv {min-height:23vw !important}
}


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{

}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
.hoverbox,.hoverbox_innerdiv {min-height:30vw !important;}
    
.bottombar .hoverbox_innerdiv .hoverbox_text { transform: none !important; }
.bottombar .hoverbox_innerdiv h2 { transform: none !important;}
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
.hoverbox,.hoverbox_innerdiv {min-height:50vw !important;} 
}

@media (max-width: 350px)
{

}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/
