:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
 }

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Hind.css";
 @import "/theme/intendit/css/modules/hoverboxar.css";   
 @import "/fontmodules/Khand.css";
 @import "/fontmodules/Satisfy.css";

 

/*Insta CSS*/
.instagram { position:relative; padding: 20px 0 40px; background:#3f5d68;  z-index:2; } /* #810404 #143564*/
.instagram h2 {  font-size: 1.7em; padding: 0 0 20px;text-align: center;   color:#000; }
.instagram figure{margin: 0;}
.instagram .slick-slider  {position: relative; z-index:1;  }
.startsida .instagram .slick, .startsida .instagram .slick-slide  { height:auto;  display:block !important;}
.instagram .imagewrapper {position: relative;}
.instagram .imagewrapper a.overlay {position: absolute; top: 0; bottom: 0; left: 0;right: 0; opacity:0; 
background-color: rgba(0, 0, 0, 0.3); color:#fff; transition: opacity 0.5s ease-in 0s;  text-shadow: 3px 3px 20px rgba(0, 0, 0, 0.8); padding: 20px; font-size: 1.2em; }
.instagram .imagewrapper:hover .overlay {opacity:1; transition:opacity 0.5s ease-in 0s; }

 
body.nyheter h1 { text-align: center;}

.nyhetsbrev { }
.nyhetsbrev .topbild {  }

.position {font-size:1.2em; color:#2873ae;  font-weight:400; }

.medarbetare h3 {color:#fff; }

.links {color:#fff; }
.links a {color:#fff;font-weight:300;  }

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #d0d0d0; background: #000; 
      line-height:1.4em;
      font-family: 'Hind', sans-serif;
      /*background: url('/theme/intendit/graphics/bg.jpg') center top/cover no-repeat fixed;*/ }
 
 
/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh; position:relative; width:calc(100% - 80px); margin:0 auto;  }  
.container {width:100%; margin: 0 auto; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#909090; font-weight:400; text-decoration: none;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%; border:none !important; }
 
/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin:10px 0 30px 30px; overflow:hidden;}
.bild span {font-style:italic; color:#606060;} /*bildtext inne på sidor*/

img.box_image {width:100%; flex:none; max-width:100%; display:block;  margin: 0 0 7px; } /*säkra vyn i IE*/
.bild img, .galleri_bild img {width:100%; height:auto; display:block;  } /*säkra vyn i IE*/
.bild img {border-radius:3px; }


.medarbetare img {
    border-radius: 100%;
    width: 150px;
    height: 150px;
    max-width: inherit;
    display: block;
    object-fit: cover;
}

/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; margin: 10px 0;}
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/

/*------------readmore & buttons----------------*/
.button, .readmore, .boltforms-row button {background: rgba(255, 255, 255, 0.15); color:#fff; padding: 6px 0 8px; display:block; margin:0; text-align: center; border-radius:2px; line-height: 1.2em; border:none; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;} .button, .boltforms-row button  { width:100%;}  
.button:hover, .readmore:hover, .boltforms-row button:hover {background: rgba(255, 255, 255, 0.15); color:#fff;  transition:background 0.5s ease-in 0s;}
.boltforms-row button, .boltforms-row button:hover { color:#fff; }
button.slick-arrow {background:none;}


/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:#143361; color:#fff;}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(0, 0, 0, 0.5); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
.sidfot .fa.round {background:rgba(255, 255, 255, 0.15); font-size:1.3em; width: 40px; height: 40px; padding: 11px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum {color:#909090; font-size:95%; display:block; clear:both;  }
.record {border-bottom:1px solid #343434; }


/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:700;  color:#fff;   font-family: 'Khand', sans-serif;}
h1{font-size: 2.6em; padding: 0px 0 10px; /*text-transform:uppercase;*/   }
h2{font-size: 1.7em; color:#b7b7b7; }
h3{font-size: 1.3em; color:#b7b7b7; }
h4, h5, h6 {font-size: 1em; }

h2.highlight {font-size:1.4em; line-height:1.2em; padding: 0px 0 15px ; color:#C1232A; font-weight:400; }
.sida.listing h1 {padding:0 0 40px;  }
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:absolute; right:10px; top:10px; z-index:1; color:#858585; font-size:0.95em; text-transform:none; letter-spacing:0.5pt;  font-weight:400;  }
.toptext a {color:#858585;}
.toptext .fa {font-size:1.2em; }
.topbild {min-height:50vh; background-position: center center; background-size: contain; background-repeat: no-repeat; position: relative; border-radius:5px;}
 

/*----------------------Bildspel-----------------------*/ 
.slick-slider {margin:0; position: relative;}
.slick .slick-slide {background-size: contain; background-repeat: no-repeat; background-position: center center; display:flex !important;  height:60vh; border-radius:5px; margin-top:1em;}   
.slick {visibility: hidden;  height:60vh; }
.slick-initialized {visibility: visible;}
.bildspel_text {padding: 20px 0; margin: 0  ; background:rgba(255, 255, 255, 0.85) ; width:100%; text-align:center;   }
.bildspel_text h1 {   font-size:1.7em;  font-weight:600; padding:0; color: inherit;   font-family: 'Hind', sans-serif;  }

 

/*Slick dots*/
.slick-dots {margin:0; bottom:auto;top:10px;   text-align:right; }
.slick-dots li button {background:#fff; border-radius:50%; width:14px; height:14px; opacity:0.6; transition: opacity 0.5s ease-in 0s;  }
.slick-dots > .slick-active > button {opacity:1; }
.slick-dots li button:before {color: transparent; content: "";}

/*Scroll effect specifik css*/
.wrapper.scroll_effect {height:calc(100vh - 80px); min-height:1px; width:100%; top: 80px;  overflow-x: hidden; overflow-y: auto; perspective: 1px; perspective-origin: 100% 50%;} 
.scroll_effect .slick, .scroll_effect .topbild_wrapper  { width: 100vw; position: absolute; top: 0; right: 0; bottom: 0;left: 0; transform: translateZ(-1px) scale(2); transform-origin: 100% 50%;} 
.scroll_layer  {position: absolute; top: 0; right: 0; bottom: 0;left: 0; transform-origin: 100% 50%; transform: translateZ(0); }  
.scroll_layer  {padding: 50vh 0 0;} .startsida .scroll_layer {padding: 70vh 0 0;}   /*sätt padding efter height på slick eller topbild*/
.scroll_layer .sida {min-height: calc(100vh - 80px - 50vh);} /*sätt efter height på topbild*/
.wrapper.scroll_effect .startsidatext, .wrapper.scroll_effect .boxar, .wrapper.scroll_effect .hoverboxar, .wrapper.scroll_effect .sida {background:#fff;} 


/*IE*/
.IE11 .wrapper.scroll_effect, .IE10 .wrapper.scroll_effect, .IE9 .wrapper.scroll_effect { perspective:none; perspective-origin: 50% 50%; }
.IE11 .scroll_effect .slick, .IE10 .scroll_effect .slick, .IE9 .scroll_effect .slick, .IE11 .scroll_effect .topbild_wrapper, 
.IE10 .scroll_effect .topbild_wrapper,.IE9 .scroll_effect .topbild_wrapper  { position: relative; transform: none; transform-origin: 50% 50%;} 
.IE11 .scroll_layer, .IE10 .scroll_layer, .IE9 .scroll_layer  {position: relative; padding:0;  transform-origin: 50% 50%; transform:none; }  


/* ------------------------------------------------------------Tables--------------------------------------------------------------------------*/
table { margin:30px 0; font-size:90%; width:100% !important; padding: 30px; background:#101010;   }
tbody {width:100% !important; display:table; }
table tr td {width:33%; }
table tr:first-child {width:100%; border-bottom: 1px solid #e4e4e4; color:#fff; font-weight:400; font-size:1.2em; }
table tr:first-child td { padding-bottom: 5px; }
table tr:nth-child(2) td { padding-top: 5px; }

table tr td {margin: 0; padding:0px 30px 0px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:0px 0px 0px 0;}


.nyhetsbrev form {padding:  30px; background:#143361;  color:#fff; width:100%  ; max-width:550px; }
.nyhetsbrev table { margin: 0; padding:0; font-size:1em; width:100% !important; max-width:550px;  background:none; text-align:left; }
.nyhetsbrev table tr td {min-width:70%; }
.nyhetsbrev table tr td input {width:100%; }

.nyhetsbrev table tr  {width:100%; border:none  ;  font-size: 1em; font-weight: 300;}
.nyhetsbrev table tr th  {width:30%;  }

.nyhetsbrev table tr td  {padding:0; border:none  ; }
.nyhetsbrev table th   { border:none;  padding: 0; font-weight:300; }
.nyhetsbrev table th label  { text-align:left; font-weight:300; margin-bottom: 5px; }
.nyhetsbrev form .knapp {width:100%; border:none; background:#000; color:#fff; margin:0;  }



/*----------------------Startsidatext-----------------------*/
.startsidatext {padding: 50px 0; margin: 0 ; background:#101010 ;   width:100%;  }
.startsidatext h1 {padding:0 0 5px; font-size:2em; color:#fff ; font-weight:600; }
.startsidatext_textdiv {  } 

/*----------------------Boxar-------------------------------*/
.boxar {padding: 20px 0 40px; }
.box {}
.box h2 {}
.box_textdiv {}


.hoverboxar > div { }



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #606060; }
.galleri_bild a {display:block; }
.galleri_bild img {padding:10px ; display: block; border-radius:50%; }
.thumbtitel {font-size:90%; padding: 0 20px 20px; text-align: center; line-height:0.95em; color:#909090; }  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; } 
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto; }
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
/*Kontaktbanner & Formulär*/
.boltforms-row label, .boltforms-row button {font-weight: 400; margin-bottom: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }

.kontaktbanner {background: #101010; padding:50px 0; color:#fff; box-sizing: border-box;}
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktformular .boltforms-row, .kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}


 


/*Googlekarta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}

/*Sifoten*/
footer {padding: 70px 0 120px; width:100%; background:#000;}
footer, footer a {color:#b0b0b0; font-weight:300; }
footer h3 {padding: 0 0 5px; color:#e4e4e4 !important; } 

/*intendit*/
.created-by, .created-by a  {color: #666666 !important;font-size: 0.95em;letter-spacing: 0.5pt; }
.created-by  {text-align:center; padding: 5px 0 10px; background:#050505; }
.created-by p {margin:0; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {  width:calc(100% - 80px);  background:#000; margin:0 auto;  z-index:10; top:0;  height:80px;  font-family: 'Hind', sans-serif;}
body.scrolled header {}

/*Logotype*/
.logo {padding:15px; box-sizing:border-box; } 
.logo img {max-height: 100px; width:auto; max-width:calc(100vw - 100px); display:block;  }
.logo a { color: #ffffff;
    display: block;
    font-family: Khand,sans-serif;
    font-size: 2.3em;
    font-weight: 900;
    letter-spacing: 1pt;
    padding: 0;
    }  
.logo a:hover {color: #ffffff;}


/*Standard meny + hovers*/
nav {background:inherit;  } 
header nav ul.menu > li {padding: 0 5px; margin:0;  }
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; color:#e4e4e4;font-weight:300;  transition:color 0.5s ease-in 0s; }
nav ul.menu li a:hover, nav ul.menu li.active a {color:#fff;  }
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }
nav ul.menu > li:last-child(2) > ul { left: auto; right: 0; text-align:right; }


/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){
header {position:fixed; top:0; min-height:80px; width:100%;  box-shadow:none; flex-wrap:nowrap;  } /*background på header bar*/
.logo img {max-height: 70px;  }
.logo {padding:10px 15px;  } 
    
    .wrapper {margin-top:80px; } 
nav ul.menu { background:rgba(0, 0, 0, 0.9); }
nav ul.menu.show { max-height:calc(100vh - 80px); padding: 10px 0;  }  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
/*menu icon*/
nav #menu-icon {padding: 20px 20px 5px 0;}
nav #menu-icon span {border-radius:3px; border: 2px solid #fff;}
}
 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
    
.bildspel_text {  padding: 15px 0;}   
    .bildspel_text h1 {  font-size: 1.4em;  }
    .hoverbox_text h2 { font-size: 2em;}
    .hoverbox_text p {display:none; }
    
}
   


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
    
.hoverbox_text h2 { font-size: 1.8em;}       
.toptext {  font-size: 0.85em; top: 5px;}
.hoverboxar { padding: 30px 0 60px;}
.wrapper {width:100%; }
    
.startsidatext {  background: rgba(0, 0, 0, 0.8)  ; background-image:none;  margin: 0  ;
 max-width: 100%; min-height: 10px;padding: 20px  ; width: 100%;}
.startsidatext h1  {font-size:2em;   }
.startsidatext_textdiv {font-size:1.1em; line-height:1.25em;  } 
    
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    /*Slider & topbild height*/  
.slick, .slick .slick-slide, .topbild { height:55vw;}  
.facebookimages h2 {font-size: 1.6em;      font-weight: 400;}  
.bildspel_text {  padding: 10px 0;}   
.bildspel_text h1 {  font-size: 1.2em; font-weight:400;  }  
.hoverbox_text h2 { font-size: 1.6em;}     
.toptext { display:none; }
.logo a {   font-size: 2em; }  
.startsidatext { display:none; }
 
   
/* ------------------------------------------------------------Tables--------------------------------------------------------------------------*/
 table  {font-size:0.85em; padding:0; background:none;  overflow: hidden;  }
table tr td { padding-right:   10px  ;}
    
.forestallningar   .forestallning h3 {font-size:1.1em; }  
.hoverbox {margin-bottom: 1em; }
    
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    

.scroll_layer, .startsida .scroll_layer {padding: 55vw 0 0;}  
.scroll_layer .sida {min-height:1px;} 

/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
footer .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;  padding: 20px 0;text-align:center;  max-width:400px;  }
footer .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
.galleri_bild img {margin: 0 auto; width: 60%;}   
    
    
    
 /*Slider & topbild height*/  
.slick, .slick .slick-slide, .topbild { height:65vw;}
  
.nyhetsbrev form {padding: 20px;  }
.nyhetsbrev table { font-size:0.9em;  }
.nyhetsbrev table tr td {min-width:100%; display:list-item; list-style-type:none;  }
.nyhetsbrev table tr th  {width:100%; display:list-item;  list-style-type:none; }
 
 
.hoverbox_text h2 { font-size: 2em;}    
.facebookimages h2 { font-size: 1.4em; font-weight: 400;}
.logo a {   font-size: 1.7em; }  
.medarbetare  img {width:60%; height:auto; margin: 0 auto ; display:block; }
.medarbetare .two {margin-bottom:0; padding-bottom:0; }  
 
.toptext {display:none; }       
h1 { font-size: 2em;}
    
/*sidfot*/ 
footer  {font-size: 1.1em; line-height: 1.5em; word-spacing: 2pt;}     
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild  {box-shadow:none;} 
 .galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
    
     
}

@media (max-width: 350px)
{
    
    .hoverbox_text h2 span {display:none; }
  h1 { font-size: 2em;}  
    
    
    .bildspel_text h1 {
    color: #202020;
    font-size: 0.9em;
    font-weight: 400;
}
    
    
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
